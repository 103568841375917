<template>
  <div>
    <b-overlay :show="show" rounded="lg">
      <b-card no-body>
        <b-card-header>
          <!-- title and subtitle -->
          <div style="width: 100%">
            <span>
              <b-card-title class="mb-1">CMS Questions Report</b-card-title>

              <div style="display: flex; width: 100%">
                <span style="width: 50%">
                  <b-tabs>
                    <b-tab active @click="dailyFunction(1)">
                      <template #title>
                        <span style="font-size: 0.8rem">Daily</span>
                      </template>
                    </b-tab>
                    <b-tab @click="dailyFunction(2)">
                      <template #title>
                        <span style="font-size: 0.8rem">Weekly</span>
                      </template></b-tab
                    >
                    <b-tab @click="dailyFunction(3)">
                      <template #title>
                        <span style="font-size: 0.8rem">Monthly</span>
                      </template></b-tab
                    >
                  </b-tabs>
                </span>
                <span
                  style="width: 50%; display: flex; flex-direction: row-reverse"
                >
                  <b-tabs>
                    <b-tab active @click="addEditDelete(1)">
                      <template #title>
                        <span style="font-size: 0.8rem">Added</span>
                      </template>
                    </b-tab>
                    <b-tab @click="addEditDelete(2)">
                      <template #title>
                        <span style="font-size: 0.8rem">Edited</span>
                      </template></b-tab
                    >
                    <b-tab @click="addEditDelete(3)">
                      <template #title>
                        <span style="font-size: 0.8rem">Deleted</span>
                      </template></b-tab
                    >
                  </b-tabs>
                </span>
              </div>
            </span>
            <!-- <b-card-sub-title>Commercial networks</b-card-sub-title> -->
          </div>

          <!--/ title and subtitle -->

          <!-- {{ rangePicker }} -->
          <!-- datepicker -->

          <div style="display: flex; flex-direction: row; width: 100%">
            <!-- <span v-if="spinnerPlayStop == true" style="margin-right: 25px"> -->
            <!-- <b-spinner label="Loading..." /> -->
            <!-- </span> -->
            <!-- <feather-icon icon="CalendarIcon" size="16" /> -->
            <!-- <span v-if="singleUserSpinner == true" style="margin-right: 25px">
            <b-spinner label="Loading..." />
          </span> -->
            <div style="width: 65%">
              <!-- <b-form-select
            @input="selectedUserFunc(selectedUsers)"
            v-model="selectedUsers"
            :options="optionsData"
          /> -->

              <!-- @input="selectedUserFunc" -->
              <b-form-group label="Select User"
                ><v-select
                  multiple
                  :close-on-select="true"
                  v-model="selectedUsers"
                  :options="optionsData"
                  @option:selected="selectFunction"
                  @option:deselected="deselectFunction"
                  label="text"
                  input-id="add-text"
                >
                </v-select
              ></b-form-group>
            </div>
            <div style="width: 30%; margin-left: 5%">
              <b-form-group label="Date Picker">
                <date-range-picker
                  ref="picker"
                  :opens="'left'"
                  :="optionsData"
                  :locale-data="{
                    direction: 'ltr',
                    format: 'yyyy-mm-dd',
                    separator: ' - ',
                    applyLabel: 'Apply',
                    cancelLabel: 'Cancel',
                    weekLabel: 'W',
                    customRangeLabel: 'Custom Range',
                    daysOfWeek: [
                      'Sun',
                      'Mon',
                      'Tue',
                      'Wed',
                      'Thu',
                      'Fri',
                      'Sat',
                    ],
                    monthNames: [
                      'Jan',
                      'Feb',
                      'Mar',
                      'Apr',
                      'May',
                      'Jun',
                      'Jul',
                      'Aug',
                      'Sep',
                      'Oct',
                      'Nov',
                      'Dec',
                    ],
                    firstDay: 0,
                  }"
                  :minDate="'2019-01-01 00:00:00'"
                  :maxDate="'2099-01-01 00:00:00'"
                  :timePicker="false"
                  :timePicker24Hour="true"
                  :ranges="ranges"
                  :showWeekNumbers="false"
                  :showDropdowns="true"
                  :autoApply="false"
                  v-model="dateRange"
                  @update="getAddQuestionAccordingUsers"
                  :linkedCalendars="true"
                >
                </date-range-picker>
              </b-form-group>
            </div>
            <!-- <flat-pickr
          v-model="rangePicker"
          @input="getAddQuestionAccordingUsers(rangePicker)"
          :config="{ mode: 'range' }"
          class="form-control flat-picker bg-transparent border-0 shadow-none"
          placeholder="DD-MM-YYYY"
        /> -->
          </div>
          <!-- datepicker -->
        </b-card-header>

        <!-- <div class="d-flex align-items-center">
        <strong>Loading...</strong>
        <b-spinner class="ml-auto" />
      </div> -->

        <b-card-body :key="sidebarkey">
          <chartjs-component-line-chart
            :height="250"
            :data="lineChart.data"
            :options="lineChart.options"
            :plugins="plugins"
          />
        </b-card-body>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BSpinner,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BTabs,
  BTab,
  BOverlay,
  BFormSelect,
  BFormGroup,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import Ripple from "vue-ripple-directive";
import flatPickr from "vue-flatpickr-component";
import ChartjsComponentLineChart from "./charts-components/ChartjsComponentLineChart.vue";
import "flatpickr/dist/flatpickr.css";
import axios from "axios";
import chartjsData from "./chartjsData";
import { $themeColors } from "@themeConfig";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as _ from "lodash";

export default {
  components: {
    ChartjsComponentLineChart,
    BCard,
    BFormSelect,
    BSpinner,
    BFormGroup,
    vSelect,
    BTabs,
    BOverlay,
    BTab,
    DateRangePicker,
    BCardBody,
    BCardHeader,
    VueApexCharts,
    flatPickr,
    BCardTitle,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      show: false,
      ranges: {
        //default value for ranges object (if you set this to false ranges will no be rendered)
        Today: [new Date(), new Date()],
        Yesterday: [
          new Date(new Date().setDate(new Date().getDate() - 1)),
          new Date(new Date().setDate(new Date().getDate() - 1)),
        ],
        "7 Days": [
          new Date(new Date().setDate(new Date().getDate() - 6)),
          new Date(),
        ],
        "This Week": [this.getMonday(new Date()), new Date()],
        "This Month": [new Date(new Date().setDate(1)), new Date()],
        "Last 2 Month": [
          new Date(new Date().setMonth(new Date().getMonth() - 2)),
          new Date(),
        ],
        "Custom Range": [],
      },
      singleUserSpinner: false,
      dir: "ltr",
      dateRange: {},
      selectedUsers: [],
      optionsData: [],
      permantSaveOptionValue: [],
      chartjsData,
      spinnerPlayStop: false,
      rangePicker: ["2022-05-01", "2022-05-10"],
      sidebarkey: 0,
      Picker: "2022-05-01 to 2022-05-10",
      chartColors: {
        primaryColorShade: "#836AF9",
        yellowColor: "#ffe800",
        successColorShade: "#28dac6",
        warningColorShade: "#ffe802",
        warningLightColor: "#FDAC34",
        infoColorShade: "#299AFF",
        greyColor: "#4F5D70",
        blueColor: "#2c9aff",
        blueLightColor: "#84D0FF",
        greyLightColor: "#EDF1F4",
        tooltipShadow: "rgba(0, 0, 0, 0.25)",
        lineChartPrimary: "#666ee8",
        lineChartDanger: "#ff4961",
        labelColor: "#6e6b7b",
        grid_line_color: "rgba(200, 200, 200, 0.2)",
      },
      lineChart: {
        options: {
          responsive: true,
          maintainAspectRatio: false,
          backgroundColor: false,
          hover: {
            mode: "label",
          },

          hover: {
            mode: "index",
            intersect: false,
          },

          tooltips: {
            mode: "index",
            //enabled: false,
            intersect: false,
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: "rgba(0, 0, 0, 0.25)",
            backgroundColor: "white",
            titleFontColor: "black",
            bodyFontColor: "black",
          },
          layout: {
            padding: {
              top: -15,
              bottom: -25,
              left: -15,
            },
          },
          scales: {
            xAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                gridLines: {
                  display: true,
                  color: "rgba(200, 200, 200, 0.2)",
                  zeroLineColor: "rgba(200, 200, 200, 0.2)",
                },
                ticks: {
                  autoSkip: false,
                  fontColor: "#6e6b7b",
                },
              },
            ],
            yAxes: [
              {
                display: true,
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  stepSize: 50,
                  min: 0,
                  max: 450,
                  fontColor: "#6e6b7b",
                },
                gridLines: {
                  display: true,
                  color: "rgba(200, 200, 200, 0.2)",
                  zeroLineColor: "rgba(200, 200, 200, 0.2)",
                },
              },
            ],
          },

          legend: {
            position: "top",
            align: "start",
            labels: {
              usePointStyle: true,
              padding: 25,
              boxWidth: 9,
            },
          },
        },
        data: {
          labels: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          datasets: [
            {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "0",
              borderColor: "#ff4961",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "#ff4961",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "#ff4961",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              pointShadowColor: "rgba(0, 0, 0, 0.25)",
            },
          ],
        },
      },

      plugins: [
        // to add spacing between legends and chart
        {
          beforeInit(chart) {
            /* eslint-disable func-names, no-param-reassign */
            chart.legend.afterFit = function () {
              this.height += 20;
            };
            /* eslint-enable */
          },
        },
      ],
      datetime: [],
      previousSelectedStructures: [],
      sidebarkey: 0,
      tabIndex: 1,
      addEditDeleteIndex: 1,
    };
  },
  created() {
    this.dateRange = {};

    this.firstload();
    // console.log("this.dateRangeCreated",typeof(this.dateRange))
  },
  methods: {
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1);
      // console.log('safas',new Date(new Date(d.setDate(diff)).setDate(new Date().getDate() - 1)));
      // var dt = new Date(new Date(d.setDate(diff)).setDate(new Date().getDate() - 1));
      // var day2 = dt.getDay(),
      //   diff2 = dt.getDate() - day2 + (day2 == 0 ? -6 : 1);
      // // adjust when day is sunday
      // console.log('last monday', new Date(d.setDate(diff2)));
      return new Date(d.setDate(diff));
    },
    //      getMondays() {
    //     var d = new Date('2022-06-05'),
    //         month = d.getMonth(),
    //         mondays = [];

    //     d.setDate(1);

    //     // Get the first Monday in the month
    //     while (d.getDay() !== 1) {
    //         d.setDate(d.getDate() + 1);
    //     }

    //     // Get all the other Mondays in the month
    //     while (d.getMonth() === month) {
    //         mondays.push(new Date(d.getTime()));
    //         d.setDate(d.getDate() + 7);
    //     }

    //     return mondays;
    // },
    getLastWeeksDates(d, param) {
      if (param == "last 2") {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1);
        // console.log("sfas", new Date(d.setDate(diff)));
        var secondValue = new Date(
          new Date(d.setDate(diff)).setDate(new Date().getDate() - 1)
        );
        var dt = new Date(secondValue.setDate(secondValue.getDate() - 1));
        // console.log("fasfaf", new Date(dt.setDate(dt.getDate() - 1)));
        // console.log("dt", dt);

        var day2 = dt.getDay(),
          diff2 = dt.getDate() - day2 + (day2 == 0 ? -6 : 1);
        // adjust when day is sunday

        // console.log("last monday", new Date(dt.setDate(diff2)));
        return new Date(dt.setDate(diff2));
      }
      if (param == "last 4") {
        // console.log('getMondays',this.getMondays());
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1);

        ///////////////////////2nd
        var secondValue = new Date(
          new Date(d.setDate(diff)).setDate(new Date().getDate() - 1)
        );
        var dt = new Date(secondValue.setDate(secondValue.getDate() - 1));
        // console.log("fasfaf", new Date(dt.setDate(dt.getDate() - 1)));
        // console.log("dt", dt);

        var day2 = dt.getDay(),
          diff2 = dt.getDate() - day2 + (day2 == 0 ? -6 : 1);
        // adjust when day is sunday
        // console.log("last monday", new Date(dt.setDate(diff2)));
        let thirdValue = new Date(dt.setDate(diff2));
        /////////// 3rd week
        // console.log('dt',dt.setDate(diff2));
        // console.log('dtz',new Date(dt.setDate(diff2)));
        // console.log('setdate',new Date(dt.setDate(diff2).setDate(d.getDate() - 5)));
        var dy = new Date(thirdValue.setDate(thirdValue.getDate() - 1));

        // console.log("asfas", dy);
        var day3 = dy.getDay(),
          diff3 = dy.getDate() - day3 + (day3 == 0 ? -6 : 1);
        // adjust when day is sunday
        // console.log("last monday", new Date(dy.setDate(diff3)));
        //
        /////////////////4th week
        let fourValue = new Date(dy.setDate(diff3));
        /////////// 3rd week
        // console.log('dt',dt.setDate(diff2));
        // console.log('dtz',new Date(dt.setDate(diff2)));
        // console.log('setdate',new Date(dt.setDate(diff2).setDate(d.getDate() - 5)));
        var dx = new Date(fourValue.setDate(fourValue.getDate() - 1));

        // console.log('asfas',dy);
        var day4 = dx.getDay(),
          diff4 = dx.getDate() - day4 + (day4 == 0 ? -6 : 1);
        // adjust when day is sunday
        // console.log("last monday", new Date(dx.setDate(diff4)));
        return new Date(dx.setDate(diff4));
      }
      if (param == "last 8") {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1);
        var secondValue = new Date(
          new Date(d.setDate(diff)).setDate(new Date().getDate() - 1)
        );
        var dt = new Date(secondValue.setDate(secondValue.getDate() - 1));
        // console.log("fasfaf", new Date(dt.setDate(dt.getDate() - 1)));
        // console.log("dt", dt);

        var day2 = dt.getDay(),
          diff2 = dt.getDate() - day2 + (day2 == 0 ? -6 : 1);
        // console.log("last monday", new Date(dt.setDate(diff2)));
        let thirdValue = new Date(dt.setDate(diff2));
        var dy = new Date(thirdValue.setDate(thirdValue.getDate() - 1));
        // console.log("asfas", dy);
        var day3 = dy.getDay(),
          diff3 = dy.getDate() - day3 + (day3 == 0 ? -6 : 1);
        // console.log("last monday", new Date(dy.setDate(diff3)));
        let fourValue = new Date(dy.setDate(diff3));
        var dx = new Date(fourValue.setDate(fourValue.getDate() - 1));
        var day4 = dx.getDay(),
          diff4 = dx.getDate() - day4 + (day4 == 0 ? -6 : 1);

        let fiveValue = new Date(dx.setDate(diff4));
        var dfive = new Date(fiveValue.setDate(fiveValue.getDate() - 1));
        var day5 = dfive.getDay(),
          diff5 = dfive.getDate() - day5 + (day5 == 0 ? -6 : 1);

        let sixValue = new Date(dfive.setDate(diff5));
        var dsix = new Date(sixValue.setDate(sixValue.getDate() - 1));
        var day6 = dsix.getDay(),
          diff6 = dsix.getDate() - day6 + (day6 == 0 ? -6 : 1);

        let sevenValue = new Date(dsix.setDate(diff6));
        var dseven = new Date(sevenValue.setDate(sevenValue.getDate() - 1));
        var day7 = dseven.getDay(),
          diff7 = dseven.getDate() - day7 + (day7 == 0 ? -6 : 1);

        let eightValue = new Date(dseven.setDate(diff7));
        var deight = new Date(eightValue.setDate(eightValue.getDate() - 1));
        var day8 = deight.getDay(),
          diff8 = deight.getDate() - day8 + (day8 == 0 ? -6 : 1);
        // console.log("last monday", new Date(dx.setDate(diff4)));
        return new Date(deight.setDate(diff8));
      }
    },
    addEditDelete(param) {
      if (param) {
        this.addEditDeleteIndex = param;
      }
      if (this.tabIndex == 1) {
        if (this.addEditDeleteIndex == 1) {
          this.addGraphDaily(this.dateRange);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphDaily(this.dateRange);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphDaily(this.dateRange);
        }
      }
      if (this.tabIndex == 2) {
        if (this.addEditDeleteIndex == 1) {
          this.addGraphWeekly(this.dateRange);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphWeekly(this.dateRange);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphWeekly(this.dateRange);
        }
      }
      if (this.tabIndex == 3) {
        if (this.addEditDeleteIndex == 1) {
          this.addGraphMonthly(this.dateRange);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphMonthly(this.dateRange);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphMonthly(this.dateRange);
        }
      }
    },
    dailyFunction(param) {
      this.tabIndex = param;
      if (param == 1) {
        this.ranges = {
          //default value for ranges object (if you set this to false ranges will no be rendered)
          Today: [new Date(), new Date()],
          Yesterday: [
            new Date(new Date().setDate(new Date().getDate() - 1)),
            new Date(new Date().setDate(new Date().getDate() - 1)),
          ],
          "7 Days": [
            new Date(new Date().setDate(new Date().getDate() - 6)),
            new Date(),
          ],
          "This Week": [this.getMonday(new Date()), new Date()],
          "This Month": [new Date(new Date().setDate(1)), new Date()],
          "Last 2 Month": [
            new Date(new Date().setMonth(new Date().getMonth() - 2)),
            new Date(),
          ],
          "Custom Range": [],
        };
        if (this.addEditDeleteIndex == 1) {
          this.addGraphDaily(this.dateRange);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphDaily(this.dateRange);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphDaily(this.dateRange);
        }
      }
      if (param == 2) {
        let today = new Date();
        let monday = new Date(today);
        monday.setDate(today.getDate() - today.getDay() + 1);
        let two_week = new Date(monday);
        let last_two_week = new Date(two_week.setDate(monday.getDate() - 7));
        today = new Date();
        monday = new Date(today);
        monday.setDate(today.getDate() - today.getDay() + 1);
        two_week = new Date(monday);
        let last_four_week = new Date(two_week.setDate(monday.getDate() - 21));
        today = new Date();
        monday = new Date(today);
        monday.setDate(today.getDate() - today.getDay() + 1);
        two_week = new Date(monday);
        let last_eight_week = new Date(two_week.setDate(monday.getDate() - 49));
        // console.log("two week", two_week.setDate(monday.getDate() - 14));
        // add 14 days (2 weeks)
        // console.log("monday 2 weeks later", two_week);
        this.ranges = {
          //default value for ranges object (if you set this to false ranges will no be rendered)
          "Last 2 Week": [last_two_week, new Date()],
          "Last 4 Week": [last_four_week, new Date()],
          "Last 8 Week": [last_eight_week, new Date()],
          "Custom Range": [],
        };
        if (this.addEditDeleteIndex == 1) {
          this.addGraphWeekly(this.dateRange);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphWeekly(this.dateRange);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphWeekly(this.dateRange);
        }
      }
      if (param == 3) {
        const today = new Date();
        const firstDayOfPreviousMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 1,
          1
        );
        const threeDayOfPreviousMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 2,
          1
        );
        const sixDayOfPreviousMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 5,
          1
        );
        const twelveDayOfPreviousMonth = new Date(
          today.getFullYear(),
          today.getMonth() - 11,
          1
        );
        this.ranges = {
          //default value for ranges object (if you set this to false ranges will no be rendered)
          "Last 2 Month": [firstDayOfPreviousMonth, new Date()],
          //  "Last 2 Month": [ new Date(new Date().setDate(new Date().getDate() - 60)), new Date()],
          "Last 3 Month": [threeDayOfPreviousMonth, new Date()],
          "Last 6 Month": [sixDayOfPreviousMonth, new Date()],
          "Last 12 Month": [twelveDayOfPreviousMonth, new Date()],
          "Custom Range": [],
        };
        if (this.addEditDeleteIndex == 1) {
          // console.log('fsas',);
          this.addGraphMonthly(this.dateRange);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphMonthly(this.dateRange);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphMonthly(this.dateRange);
        }
      }
    },
    deselectFunction(data) {
      this.show = true;
      // console.log(0, value);
      // for (let k = 0; k < removed.length; k++) {
      // const element = array[k];
      this.filterEmpty(data.value);
      let conditionValue = false;
      for (let l = 0; l < this.optionsData.length; l++) {
        // const element = array[l];
        if (this.optionsData[l].value == data.value) {
          conditionValue = false;
          // console.log("condition true");
          break;
        } else {
          conditionValue = true;
        }
      }
      if (conditionValue) {
        // console.log("save value");

        this.optionsData.push(data);
      }
      // }

      var finalData = {
        id: data.value,
      };
      axios
        .post(
          process.env.VUE_APP_API_URL + "/remove-selected/user",
          finalData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(respo)
          this.forcRender();
          this.show = false;
        });
    },
    selectFunction(data) {
      // console.log(1,value);
      this.show = true;
      //   return  'fdfs';
      this.singleUserSpinner = true;
      var userIdsArr = [];
      var datetime = {};
      var valueLength = data.length;
      userIdsArr.push(data[valueLength - 1].value);
      //   for (let i = 0; i < data.length; i++) {
      //     if (data[i].value) {
      //       userIdsArr.push(data[i].value);
      //       //
      //     }
      //   }
      var startDate = this.dateRange.startDate;
      var endDate = this.dateRange.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // if (startDate == lastDate) {
      // var datetime = `${startDate}`;
      // } else {
      var datetime = `${startDate + " " + "to" + " " + lastDate}`;
      // }
      //   console.log('datetime',datetime);
      var finalData = {
        id: userIdsArr,
        date: datetime,
      };
      axios
        .post(
          process.env.VUE_APP_API_URL + "/addselect/user/report",
          finalData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(this.permantSaveOptionValue, "sfasfas");
          // this.optionsData = response.data.remaingUsers;
          // this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              UserId: "",
            };
            // alert(_.some( this.lineChart.data.datasets,response.data.data[index]));
            // if(_.some( this.lineChart.data.datasets,response.data.data[index])){

            //   }else{
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.UserId = response.data.data[index].UserId;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            // alert(_.some(this.lineChart.data.datasets, createObject));
            // }
            if (_.some(this.lineChart.data.datasets, createObject)) {
              // this.filterEmpty(createObject['UserId']);
            } else {
              this.lineChart.data.datasets.push(createObject);
            }
            // this.filterEmpty(response.data.data[index].lineColor);
          }
          // this.lineChart.data.datasets.concat(this.permantSaveOptionValue);
          this.forcRender();
          this.singleUserSpinner = false;
          this.show = false;
        });
    },
    filterEmpty(filterArr) {
      // console.log("valuefilter", filterArr);
      var index = this.lineChart.data.datasets
        .map((x) => {
          return x.UserId;
        })
        .indexOf(filterArr);

      // console.log("this.optionsDat/a",index);
      // if (index >= 0) {
      this.lineChart.data.datasets.splice(index, 1);
      // console.log("this.lineChart.data.datasets", this.lineChart.data.datasets);
      // this.previousSelectedStructures = [];
      // }
    },
    firstload() {
      this.show = true;
      this.spinnerPlayStop = true;
      axios
        .get(
          process.env.VUE_APP_API_URL + "/last-seven/days/add-question/report",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          this.previousSelectedStructures = this.selectedUsers;
          // let added = this.selectedUsers.filter(
          //   (val) => !this.previousSelectedStructures.includes(val)
          // );
          var dateArr = [];
          for (let l = 0; l < response.data.date.length; l++) {
            //  const element = array[l];
            // let month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
            var singleDate = response.data.date[l];
            var days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
            var d = new Date(singleDate);
            // console.log(d.getDay());
            var dayName = days[d.getDay()];
            dateArr.push(dayName);
          }
          this.lineChart.data.labels = dateArr;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",

              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
              UserId: "",
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.UserId = response.data.data[index].UserId;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.data.datasets = newArr;
          this.permantSaveOptionValue = JSON.stringify(
            this.lineChart.data.datasets
          );
          this.permantSaveOptionValue = JSON.parse(this.permantSaveOptionValue);
          // this.rangePicker = [];
          // this.rangePicker.push(response.data.date[0]);
          // this.rangePicker.push(response.data.date[6]);
          var a = new Date();
          // console.log("a",a);
          var b = -6;
          const today = new Date();
          const monday = new Date(today);
          monday.setDate(today.getDate() - today.getDay() + 1); // subtract days until Monday
          const mondayDate = monday.toISOString().slice(0, 10);
          var resulta = a.setDate(a.getDate() + b);
          // console.log(new Date(result))
          var someDate = new Date();
          var numberOfDaysToAdd = 0;
          var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
          this.dateRang = {};
          // console.log("this.dateRange1", typeof this.dateRange);
          this.dateRange = {
            endDate: new Date(result),
            startDate: new Date(mondayDate),
          };
          // console.log("this.dateRange2", typeof this.dateRange);
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    getAddQuestionAccordingUsers(value) {
      this.spinnerPlayStop = true;

      if (this.tabIndex == 1) {
        if (this.addEditDeleteIndex == 1) {
          this.addGraphDaily(value);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphDaily(value);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphDaily(value);
        }
      }
      if (this.tabIndex == 2) {
        if (this.addEditDeleteIndex == 1) {
          this.addGraphWeekly(value);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphWeekly(value);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphWeekly(value);
        }
      }
      if (this.tabIndex == 3) {
        if (this.addEditDeleteIndex == 1) {
          this.addGraphMonthly(value);
        }
        if (this.addEditDeleteIndex == 2) {
          this.editGraphMonthly(value);
        }
        if (this.addEditDeleteIndex == 3) {
          this.deleteGraphMonthly(value);
        }
      }
    },
    addGraphDaily(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/dailyreport/add", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response.data);

          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.data.datasets = newArr;
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          if (typeof response.data.date != "string") {
            this.lineChart.data.labels = response.data.date;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },
    editGraphDaily(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/dailyreport/edit", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response.data);

          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.data.datasets = newArr;
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          if (typeof response.data.date != "string") {
            this.lineChart.data.labels = response.data.date;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },
    deleteGraphDaily(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/dailyreport/delete", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response.data);

          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.data.datasets = newArr;
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          if (typeof response.data.date != "string") {
            this.lineChart.data.labels = response.data.date;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },

    addGraphWeekly(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // if (startDate == lastDate) {
      //   var datetime = {
      //     startDate: `${startDate}`,
      //   };
      // } else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/weeklyreport/add", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          this.lineChart.data.datasets = newArr;
          var dateArr = [];
          // console.log('response.data.date',response.data.date);
          for (let i = 0; i < response.data.date.length; i++) {
            let newDate = response.data.date[i].split("to");
            // console.log(newDate[0]);
            var monthfind1 = new Date(newDate[0]);
            var month1 = monthfind1.toLocaleString("default", {
              month: "short",
            });
            //  console.log(month);
            var day1 = newDate[0].split("-");
            var monthfind2 = new Date(newDate[1]);
            var month2 = monthfind2.toLocaleString("default", {
              month: "short",
            });
            //  console.log(month);
            var day2 = newDate[1].split("-");
            var finaldate =
              day1[2] + " " + month1 + " " + "-" + " " + day2[2] + " " + month2;
            dateArr.push(finaldate);
          }
          // console.log(dateArr,'fas');

          if (typeof dateArr != "string") {
            this.lineChart.data.labels = dateArr;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },
    editGraphWeekly(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // if (startDate == lastDate) {
      //   var datetime = {
      //     startDate: `${startDate}`,
      //   };
      // } else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/weeklyreport/edit", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          this.lineChart.data.datasets = newArr;
          var dateArr = [];
          // console.log('response.data.date',response.data.date);
          for (let i = 0; i < response.data.date.length; i++) {
            let newDate = response.data.date[i].split("to");
            // console.log(newDate[0]);
            var monthfind1 = new Date(newDate[0]);
            var month1 = monthfind1.toLocaleString("default", {
              month: "short",
            });
            //  console.log(month);
            var day1 = newDate[0].split("-");
            var monthfind2 = new Date(newDate[1]);
            var month2 = monthfind2.toLocaleString("default", {
              month: "short",
            });
            //  console.log(month);
            var day2 = newDate[1].split("-");
            var finaldate =
              day1[2] + " " + month1 + " " + "-" + " " + day2[2] + " " + month2;
            dateArr.push(finaldate);
          }
          // console.log(dateArr,'fas');

          if (typeof dateArr != "string") {
            this.lineChart.data.labels = dateArr;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },
    deleteGraphWeekly(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // if (startDate == lastDate) {
      //   var datetime = {
      //     startDate: `${startDate}`,
      //   };
      // } else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/weeklyreport/delete", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          this.lineChart.data.datasets = newArr;
          var dateArr = [];
          // console.log('response.data.date',response.data.date);
          for (let i = 0; i < response.data.date.length; i++) {
            let newDate = response.data.date[i].split("to");
            // console.log(newDate[0]);
            var monthfind1 = new Date(newDate[0]);
            var month1 = monthfind1.toLocaleString("default", {
              month: "short",
            });
            //  console.log(month);
            var day1 = newDate[0].split("-");
            var monthfind2 = new Date(newDate[1]);
            var month2 = monthfind2.toLocaleString("default", {
              month: "short",
            });
            //  console.log(month);
            var day2 = newDate[1].split("-");
            var finaldate =
              day1[2] + " " + month1 + " " + "-" + " " + day2[2] + " " + month2;
            dateArr.push(finaldate);
          }
          // console.log(dateArr,'fas');

          if (typeof dateArr != "string") {
            this.lineChart.data.labels = dateArr;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },

    addGraphMonthly(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // if (startDate == lastDate) {
      //   var datetime = {
      //     startDate: `${startDate}`,
      //   };
      // } else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/monthlyreport/add", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          this.lineChart.data.datasets = newArr;
          if (typeof response.data.date != "string") {
            this.lineChart.data.labels = response.data.date;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },
    editGraphMonthly(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // if (startDate == lastDate) {
      //   var datetime = {
      //     startDate: `${startDate}`,
      //   };
      // } else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/monthlyreport/edit", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          this.lineChart.data.datasets = newArr;
          if (typeof response.data.date != "string") {
            this.lineChart.data.labels = response.data.date;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },
    deleteGraphMonthly(value) {
      this.show = true;
      var startDate = value.startDate;
      var endDate = value.endDate;
      var month = startDate.getMonth() + 1;
      var day = startDate.getDate();
      var year = startDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var startDate = year + "-" + month + "-" + day;
      var month = endDate.getMonth() + 1;
      var day = endDate.getDate();
      var year = endDate.getFullYear();
      if (day >= 10) {
      } else {
        day = "0" + day;
      }
      if (month >= 10) {
      } else {
        month = "0" + month;
      }
      var lastDate = year + "-" + month + "-" + day;
      // if (startDate == lastDate) {
      //   var datetime = {
      //     startDate: `${startDate}`,
      //   };
      // } else {
      var datetime = {
        startDate: `${lastDate}`,
        endDate: `${startDate}`,
        //date: `${startDate + " " + "to" + " " + lastDate}`,
        // };
      };
      axios
        .post(process.env.VUE_APP_API_URL + "/monthlyreport/delete", datetime, {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((response) => {
          // console.log(response);
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
              label: "",
              borderColor: "",
              lineTension: 0.5,
              pointStyle: "circle",
              backgroundColor: "",
              fill: false,
              pointRadius: 1,
              pointHoverRadius: 5,
              pointHoverBorderWidth: 5,
              pointBorderColor: "transparent",
              pointHoverBorderColor: $themeColors.white,
              pointHoverBackgroundColor: "",
              pointShadowOffsetX: 1,
              pointShadowOffsetY: 1,
              pointShadowBlur: 5,
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.borderColor = response.data.data[index].lineColor;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.pointHoverBackgroundColor =
              response.data.data[index].lineColor;
            newArr[index] = createObject;
          }
          this.lineChart.options.scales.yAxes[0].ticks.max =
            response.data.maxYaxis;
          this.lineChart.data.datasets = newArr;
          if (typeof response.data.date != "string") {
            this.lineChart.data.labels = response.data.date;
          } else {
            var a = [];
            a.push(response.data.date);
            this.lineChart.data.labels = a;
          }
          this.spinnerPlayStop = false;
          this.forcRender();
          this.show = false;
        });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>


