<template>
  <div>
    <b-row>
      <b-col lg="12" md="12">
        <!-- <chart-data-cmp /> -->
      </b-col>
    </b-row>
    <b-overlay :show="showvalue" rounded="lg">
      <b-card no-body>
        <!-- <b-card-header>
          <div style="width: 100%">
            <span>
              <b-card-title class="mb-1">CMS Users Report</b-card-title>

              <div style="display: flex; width: 100%">
                <span style="width: 40%" :key="sidebarkey">
                  <b-form-group label="Select User" style="width: 50%">
                    <v-select
                      :close-on-select="true"
                      v-model="selectedUsers"
                      :options="optionsData"
                      @option:selected="selectFunction"
                      @option:deselected="deselectFunction"
                      label="text"
                      input-id="add-text"
                    >
                    </v-select>
                  </b-form-group>
                </span>
                <span style="width: 33%">
                  <b-form-group label="Questions Report">
                    <b-tabs>
                      <b-tab active @click="addEditDelete(1)">
                        <template #title>
                          <span>Daily</span>
                        </template>
                      </b-tab>
                      <b-tab @click="addEditDelete(2)">
                        <template #title>
                          <span>Weekly</span>
                        </template></b-tab
                      >
                      <b-tab @click="addEditDelete(3)">
                        <template #title>
                          <span>Monthly</span>
                        </template></b-tab
                      >
                    </b-tabs>
                  </b-form-group>
                </span>
                <span
                  style="width: 33%; display: flex; flex-direction: row-reverse"
                >
                  <b-form-group label="Date Picker">
                    <date-range-picker
                      ref="picker"
                      :opens="'left'"
                      :locale-data="{
                        direction: 'ltr',
                        format: 'yyyy-mm-dd',
                        separator: ' - ',
                        applyLabel: 'Apply',
                        cancelLabel: 'Cancel',
                        weekLabel: 'W',
                        customRangeLabel: 'Custom Range',
                        daysOfWeek: [
                          'Sun',
                          'Mon',
                          'Tue',
                          'Wed',
                          'Thu',
                          'Fri',
                          'Sat',
                        ],
                        monthNames: [
                          'Jan',
                          'Feb',
                          'Mar',
                          'Apr',
                          'May',
                          'Jun',
                          'Jul',
                          'Aug',
                          'Sep',
                          'Oct',
                          'Nov',
                          'Dec',
                        ],
                        firstDay: 0,
                      }"
                      :minDate="'2019-01-01 00:00:00'"
                      :maxDate="'2099-01-01 00:00:00'"
                      :timePicker="false"
                      :ranges="ranges"
                      :timePicker24Hour="true"
                      :showWeekNumbers="false"
                      :showDropdowns="true"
                      :autoApply="false"
                      v-model="dateRange"
                      @update="getAddQuestionAccordingUsers"
                      :linkedCalendars="true"
                    >
                    </date-range-picker>
                  </b-form-group>
                </span>
              </div>
            </span>
          </div>
        </b-card-header> -->

        <!-- <b-card-body :key="sidebarkey">
          <div class="col-12 hidden-md-down">
            <chartjs-component-bar-chart
              :data="latestBarChart.data"
              :options="latestBarChart.options"
            />
          </div>
        </b-card-body> -->
      </b-card>
    </b-overlay>
    <b-overlay :show="questionTypeValue" rounded="lg">
      <b-card no-body>
        <div class="m-2">
          <b-row>
            <b-col cols="6" md="4">
              <div>
                <b-form-group label="Select Question type">
                  <v-select
                    :close-on-select="true"
                    v-model="selectQuestion"
                    :options="questionType"
                    @input="selectQuestionType(selectQuestion)"
                    label="name"
                    input-id="add-name"
                  >
                  </v-select>
                </b-form-group>
              </div>
            </b-col>
          </b-row>
        </div>

        <b-table
          :items="questionData"
          responsive
          :fields="fields"
          class="mb-0"
          id="custom-table"
          :per-page="perPage"
          :current-page="currentPage"
        >
          <!-- {{index}} -->
          <template #cell(index)="data">
            <div class="d-flex align-items-center">
              {{ data.index + 1 }}
            </div>
          </template>
          <template #cell(class)="data">
            <div class="d-flex align-items-center">
              {{ data.item.className }}
            </div>
          </template>
          <template #cell(subject)="data">
            <div class="d-flex align-items-center">
              {{ data.item.subjectName }}
            </div>
          </template>
          <template #cell(topic)="data">
            <div class="d-flex align-items-center">
              {{ data.item.topicName }}
            </div>
          </template>
          <template #cell(level)="data">
            <div class="d-flex align-items-center">
              {{ data.item.levelName }}
            </div>
          </template>
          <template #cell(noofquestion)="data">
            <div class="d-flex align-items-center">
              {{ data.item.numberOfQuestion }}
            </div>
          </template>
        </b-table>

        <div style="display: flex; flex-direction: row-reverse">
          <div>
            <b-pagination
              v-model="currentPage"
              :total-rows="rows"
              :per-page="perPage"
              aria-controls="custom-table"
              size="sm"
            ></b-pagination>
          </div>
          <div>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BOverlay,
  BFormSelect,
  BTabs,
  BTab,
  BTable,
  BPagination,
  BFormGroup,
  BCardTitle,
  BCol,
  BRow,
} from "bootstrap-vue";
// import flatPickr from "vue-flatpickr-component";
import ChartjsComponentBarChart from "./components/charts-components/ChartjsComponentBarChart.vue";
import { $themeColors } from "@themeConfig";
import chartDataCmp from "./components/chartDataCmp.vue";

import axios from "axios";
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import * as _ from "lodash";
export default {
  components: {
    BCard,
    BTabs,
    BTab,
    BCardHeader,
    BFormSelect,
    BTable,
    BPagination,
    BFormGroup,
    vSelect,
    BOverlay,
    chartDataCmp,
    BCardBody,
    BCardTitle,
    BCol,
    DateRangePicker,
    BRow,
    ChartjsComponentBarChart,
  },
  data() {
    return {
      perPageOptions: [10, 25, 50, 100],
      graphData: [],
      selectedUsers: { value: "", text: "" },
      optionsData: [],
      showvalue: false,
      rangePicker: ["2019-05-01", "2019-05-10"],
      latestBarChart: {
        data: {
          labels: [2017, 2018, 2019, 2020, 2021, 2022, 2023],
          datasets: [
            {
              label: "All data add",
              type: "bar",
              stack: "Stack 0",
              backgroundColor: "#eece01",
              data: [30, 31, 32, 33, 34, 35, -36],
            },
            {
              label: "All data delete",

              type: "bar",

              stack: "Stack 0",
              backgroundColor: "#87d84d",
              data: [-30, -16, -17, 18, -19, 20, -21],
            },
            {
              label: "All data edit",
              type: "bar",

              stack: "Stack 0",
              backgroundColor: "#f8981f",
              data: [20, -21, 22, 23, 24, -25, 26],
            },
          ],
        },

        options: {
          hover: {
            mode: "label",
          },

          hover: {
            mode: "index",
            intersect: false,
          },

          tooltips: {
            mode: "index",
            //enabled: false,
            intersect: false,
            position: "nearest",
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: "rgba(0, 0, 0, 0.25)",
            backgroundColor: "white",
            titleFontColor: "black",
            bodyFontColor: "black",
          },
          responsive: true,
          legend: {
            display: false,
          },
          interaction: {
            intersect: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                ticks: {
                  autoSkip: false,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
              },
            ],
          },
        },
      },
      sidebarkey: 0,
      filterIndex: 1,
      perPage: 10,
      currentPage: 1,
      questionTypeValue: false,
      ranges: {
        //default value for ranges object (if you set this to false ranges will no be rendered)
        Today: [new Date(), new Date()],
        Yesterday: [
          new Date(new Date().setDate(new Date().getDate() - 1)),
          new Date(new Date().setDate(new Date().getDate() - 1)),
        ],
        "7 Days": [
          new Date(new Date().setDate(new Date().getDate() - 6)),
          new Date(),
        ],
        "This Week": [this.getMonday(new Date()), new Date()],
        "This Month": [new Date(new Date().setDate(1)), new Date()],
        "Last 2 Month": [
          new Date(new Date().setMonth(new Date().getMonth() - 2)),
          new Date(),
        ],
        "Custom Range": [],
      },
      fields: [
        "index",
        { key: "class", label: "CLASS" },
        { key: "subject", label: "SUBJECT" },
        { key: "topic", label: "TOPIC" },
        { key: "level", label: "LEVEL" },
        { key: "noofquestion", label: "NOOFQUESTION" },
      ],
      selectQuestion: [],
      questionType: [],
      questionData: [],
    };
  },
  computed: {
    rows() {
      // return 39;
      return this.questionData.length;
    },
  },
  created() {
    this.dateRange = {};
    //  this.show = true;
    // this.firstload();
    this.getAllQuestionType();
    // console.log("this.dateRangeCreated",typeof(this.dateRange))
  },
  methods: {
    selectQuestionType(data) {
      this.questionTypeValue = true;
      // console.log(data);
      if (data != null) {
        let postData = {
          questionType: data,
        };
        axios
          .post(
            process.env.VUE_APP_API_URL + "/questionType/filter",
            postData,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.questionData = [];

            this.questionData = response.data.data;
            console
            this.questionTypeValue = false;
          });
      } else {
        this.questionData = [];
        this.questionTypeValue = false;
      }
    },
    getAllQuestionType() {
      this.questionTypeValue = true;
      axios
        .get(process.env.VUE_APP_API_URL + "/get-questiontype/all", {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("_t"),
          },
        })
        .then((get_response) => {
          // console.log('get_response',get_response.data);
          this.questionType = get_response.data;
          this.questionTypeValue = false;
          // console.log('get',get_response.data);
        })
        .catch(function (error) {
          console.log("error", error);
        });
    },
    getLastWeeksDates(d, param) {
      if (param == "last 2") {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1);

        var secondValue = new Date(
          new Date(d.setDate(diff)).setDate(new Date().getDate() - 1)
        );
        var dt = new Date(secondValue.setDate(secondValue.getDate() - 1));
        // console.log("fasfaf", new Date(dt.setDate(dt.getDate() - 1)));
        // console.log("dt", dt);

        var day2 = dt.getDay(),
          diff2 = dt.getDate() - day2 + (day2 == 0 ? -6 : 1);
        // adjust when day is sunday
        // console.log("last monday", new Date(dt.setDate(diff2)));
        return new Date(dt.setDate(diff2));
      }
      if (param == "last 4") {
        // console.log('getMondays',this.getMondays());
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1);

        ///////////////////////2nd
        var secondValue = new Date(
          new Date(d.setDate(diff)).setDate(new Date().getDate() - 1)
        );
        var dt = new Date(secondValue.setDate(secondValue.getDate() - 1));
        // console.log("fasfaf", new Date(dt.setDate(dt.getDate() - 1)));
        // console.log("dt", dt);

        var day2 = dt.getDay(),
          diff2 = dt.getDate() - day2 + (day2 == 0 ? -6 : 1);
        // adjust when day is sunday
        // console.log("last monday", new Date(dt.setDate(diff2)));
        let thirdValue = new Date(dt.setDate(diff2));
        /////////// 3rd week
        // console.log('dt',dt.setDate(diff2));
        // console.log('dtz',new Date(dt.setDate(diff2)));
        // console.log('setdate',new Date(dt.setDate(diff2).setDate(d.getDate() - 5)));
        var dy = new Date(thirdValue.setDate(thirdValue.getDate() - 1));

        // console.log("asfas", dy);
        var day3 = dy.getDay(),
          diff3 = dy.getDate() - day3 + (day3 == 0 ? -6 : 1);
        // adjust when day is sunday
        // console.log("last monday", new Date(dy.setDate(diff3)));
        //
        /////////////////4th week
        let fourValue = new Date(dy.setDate(diff3));
        /////////// 3rd week
        // console.log('dt',dt.setDate(diff2));
        // console.log('dtz',new Date(dt.setDate(diff2)));
        // console.log('setdate',new Date(dt.setDate(diff2).setDate(d.getDate() - 5)));
        var dx = new Date(fourValue.setDate(fourValue.getDate() - 1));

        // console.log('asfas',dy);
        var day4 = dx.getDay(),
          diff4 = dx.getDate() - day4 + (day4 == 0 ? -6 : 1);
        // adjust when day is sunday
        // console.log("last monday", new Date(dx.setDate(diff4)));
        return new Date(dx.setDate(diff4));
      }
      if (param == "last 8") {
        d = new Date(d);
        var day = d.getDay(),
          diff = d.getDate() - day + (day == 0 ? -6 : 1);
        var secondValue = new Date(
          new Date(d.setDate(diff)).setDate(new Date().getDate() - 1)
        );
        var dt = new Date(secondValue.setDate(secondValue.getDate() - 1));
        // console.log("fasfaf", new Date(dt.setDate(dt.getDate() - 1)));
        // console.log("dt", dt);

        var day2 = dt.getDay(),
          diff2 = dt.getDate() - day2 + (day2 == 0 ? -6 : 1);
        // console.log("last monday", new Date(dt.setDate(diff2)));
        let thirdValue = new Date(dt.setDate(diff2));
        var dy = new Date(thirdValue.setDate(thirdValue.getDate() - 1));
        // console.log("asfas", dy);
        var day3 = dy.getDay(),
          diff3 = dy.getDate() - day3 + (day3 == 0 ? -6 : 1);
        // console.log("last monday", new Date(dy.setDate(diff3)));
        let fourValue = new Date(dy.setDate(diff3));
        var dx = new Date(fourValue.setDate(fourValue.getDate() - 1));
        var day4 = dx.getDay(),
          diff4 = dx.getDate() - day4 + (day4 == 0 ? -6 : 1);

        let fiveValue = new Date(dx.setDate(diff4));
        var dfive = new Date(fiveValue.setDate(fiveValue.getDate() - 1));
        var day5 = dfive.getDay(),
          diff5 = dfive.getDate() - day5 + (day5 == 0 ? -6 : 1);

        let sixValue = new Date(dfive.setDate(diff5));
        var dsix = new Date(sixValue.setDate(sixValue.getDate() - 1));
        var day6 = dsix.getDay(),
          diff6 = dsix.getDate() - day6 + (day6 == 0 ? -6 : 1);

        let sevenValue = new Date(dsix.setDate(diff6));
        var dseven = new Date(sevenValue.setDate(sevenValue.getDate() - 1));
        var day7 = dseven.getDay(),
          diff7 = dseven.getDate() - day7 + (day7 == 0 ? -6 : 1);

        let eightValue = new Date(dseven.setDate(diff7));
        var deight = new Date(eightValue.setDate(eightValue.getDate() - 1));
        var day8 = deight.getDay(),
          diff8 = deight.getDate() - day8 + (day8 == 0 ? -6 : 1);
        // console.log("last monday", new Date(dx.setDate(diff4)));
        return new Date(deight.setDate(diff8));
      }
    },
    getMonday(d) {
      d = new Date(d);
      var day = d.getDay(),
        diff = d.getDate() - day + (day == 0 ? -6 : 1);
      // console.log('safas',new Date(d.setDate(diff)))
      // adjust when day is sunday
      return new Date(d.setDate(diff));
    },
    addEditDelete(param) {
      // this.show = true;
      if (param == 1) {
        this.ranges = {
          //default value for ranges object (if you set this to false ranges will no be rendered)
          Today: [new Date(), new Date()],
          Yesterday: [
            new Date(new Date().setDate(new Date().getDate() - 1)),
            new Date(new Date().setDate(new Date().getDate() - 1)),
          ],
          "7 Days": [
            new Date(new Date().setDate(new Date().getDate() - 6)),
            new Date(),
          ],
          "This Week": [this.getMonday(new Date()), new Date()],
          "This Month": [new Date(new Date().setDate(1)), new Date()],
          "Last 2 Month": [
            new Date(new Date().setMonth(new Date().getMonth() - 2)),
            new Date(),
          ],
          "Custom Range": [],
        };
        this.showvalue = true;
        // value
        var startDate = this.dateRange.startDate;
        var endDate = this.dateRange.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        var datetime = {
          id: this.selectedUsers.value,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/daily-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);
            var dateArr = [];

            this.latestBarChart.data.labels = response.data.date;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      }
      if (param == 2) {
        this.ranges = {
          //default value for ranges object (if you set this to false ranges will no be rendered)
          "This Week": [this.getMonday(new Date()), new Date()],
          "Last 2 Week": [
            this.getLastWeeksDates(new Date(), "last 2"),
            new Date(),
          ],
          "Last 4 Week": [
            this.getLastWeeksDates(new Date(), "last 4"),
            new Date(),
          ],
          "Last 8 Week": [
            this.getLastWeeksDates(new Date(), "last 8"),
            new Date(),
          ],
          "Custom Range": [],
        };
        this.showvalue = true;
        var startDate = this.dateRange.startDate;
        var endDate = this.dateRange.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        var datetime = {
          id: this.selectedUsers.value,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/weekly-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);
            var dateArr = [];

            // console.log('response.data.date',response.data.date);
            for (let i = 0; i < response.data.date.length; i++) {
              let newDate = response.data.date[i].split("to");
              // console.log(newDate[0]);
              var monthfind1 = new Date(newDate[0]);
              var month1 = monthfind1.toLocaleString("default", {
                month: "short",
              });
              //  console.log(month);
              var day1 = newDate[0].split("-");
              var monthfind2 = new Date(newDate[1]);
              var month2 = monthfind2.toLocaleString("default", {
                month: "short",
              });
              //  console.log(month);
              var day2 = newDate[1].split("-");
              var finaldate =
                day1[2] +
                " " +
                month1 +
                " " +
                "-" +
                " " +
                day2[2] +
                " " +
                month2;
              dateArr.push(finaldate);
            }
            // console.log(dateArr,'fas');

            this.latestBarChart.data.labels = dateArr;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      }
      if (param == 3) {
        this.ranges = {
          //default value for ranges object (if you set this to false ranges will no be rendered)
          "This Month": [
            new Date(new Date().setDate(new Date().getDate() - 30)),
            new Date(),
          ],
          //  "Last 2 Month": [ new Date(new Date().setDate(new Date().getDate() - 60)), new Date()],
          "Last 3 Month": [
            new Date(new Date().setDate(new Date().getDate() - 90)),
            new Date(),
          ],
          "Last 6 Month": [
            new Date(new Date().setDate(new Date().getDate() - 180)),
            new Date(),
          ],
          "Last 12 Month": [
            new Date(new Date().setDate(new Date().getDate() - 365)),
            new Date(),
          ],
          "Custom Range": [],
        };
        this.showvalue = true;
        var startDate = this.dateRange.startDate;
        var endDate = this.dateRange.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        var datetime = {
          id: this.selectedUsers.value,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/monthly-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);
            var dateArr = [];

            this.latestBarChart.data.labels = response.data.date;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      }
      this.filterIndex = param;
    },
    deselectFunction(data) {
      //   console.log(0, data);
      //    return  'fdfs';
      // for (let k = 0; k < removed.length; k++) {
      // const element = array[k];
      this.filterEmpty(data.value);
      let conditionValue = false;
      for (let l = 0; l < this.optionsData.length; l++) {
        // const element = array[l];
        if (this.optionsData[l].value == data.value) {
          conditionValue = false;
          // console.log("condition true");
          break;
        } else {
          conditionValue = true;
        }
      }
      if (conditionValue) {
        // console.log("save value");

        this.optionsData.push(data);
      }
      // }
      this.forcRender();
      var finalData = {
        id: data.value,
      };
      axios
        .post(
          process.env.VUE_APP_API_URL + "/REMOVE_SELECTED_USERS_LOGIN_USERS",
          finalData,
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          // console.log(respo)
        });
    },
    selectFunction(data) {
      // console.log(this.dateRange ,' this.dateRangedasf');
      this.selectedUsers = data;
      if (this.filterIndex == 1) {
        this.showvalue = true;
        // value
        var startDate = this.dateRange.startDate;
        var endDate = this.dateRange.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        var datetime = {
          id: this.selectedUsers.value,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/daily-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);
            var dateArr = [];

            this.latestBarChart.data.labels = response.data.date;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      } else if (this.filterIndex == 2) {
        this.showvalue = true;
        var startDate = this.dateRange.startDate;
        var endDate = this.dateRange.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        var datetime = {
          id: this.selectedUsers.value,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/weekly-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);
            var dateArr = [];

            this.latestBarChart.data.labels = response.data.date;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      } else {
        this.showvalue = true;
        var startDate = this.dateRange.startDate;
        var endDate = this.dateRange.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        var datetime = {
          id: this.selectedUsers.value,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/monthly-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);
            var dateArr = [];

            this.latestBarChart.data.labels = response.data.date;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      }
    },
    filterEmpty(filterArr) {
      this.showvalue = true;
      //   console.log("valuefilter", filterArr);
      var index = this.latestBarChart.data.datasets
        .map((x) => {
          return x.UserId;
        })
        .indexOf(filterArr);

      // console.log("this.optionsDat/a",index);
      // if (index >= 0) {
      //   this.latestBarChart.data.datasets.splice(index, 1);
      for (let i = 0; i < 3; i++) {
        this.latestBarChart.data.datasets.splice(index, 1);
      }
      for (let j = 0; j < this.graphData.length; j++) {
        if (this.graphData[j]._id == filterArr) {
          this.graphData.splice(j, 1);
          return "done";
        }
      }

      //   console.log(
      //     "this.lineChart.data.datasets",
      //     this.latestBarChart.data.datasets
      //   );
      // this.previousSelectedStructures = [];
      // }
    },
    singleUserGraphAdd(graphdata) {
      // this.show =  true;
      var UserIdsArr = [];
      for (let i = 0; i < graphdata.length; i++) {
        if (graphdata[i].UserId != "") {
          UserIdsArr.push(graphdata[i].UserId);
        }
      }
      let uniqueChars = [...new Set(UserIdsArr)];
      for (let j = 0; j < uniqueChars.length; j++) {
        let latestBarChart = {
          data: {
            labels: [2017, 2018, 2019, 2020, 2021, 2022, 2023],
            datasets: [
              {
                label: "All data add",
                type: "bar",
                stack: "Stack 0",
                backgroundColor: "#eece01",
                data: [30, 31, 32, 33, 34, 35, -36],
              },
            ],
          },

          options: {
            hover: {
              mode: "label",
            },

            hover: {
              mode: "index",
              intersect: false,
            },
            tooltips: {
              mode: "index",
              //enabled: false,
              position: "nearest",
              intersect: false,
              // Updated default tooltip UI
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowBlur: 8,
              shadowColor: "rgba(0, 0, 0, 0.25)",
              backgroundColor: "white",
              titleFontColor: "black",
              bodyFontColor: "black",
            },
            responsive: true,
            legend: {
              display: true,
            },
            interaction: {
              intersect: false,
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  labelAngle: -50,
                  ticks: {
                    autoSkip: false,

                    // beginAtZero: true,
                    // maxRotation: 0,
                    // minRotation: 0,
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                },
              ],
            },
          },
        };
        let newArr = [];
        for (let k = 0; k < graphdata.length; k++) {
          //   var createObject = {};
          if (graphdata[k].UserId == uniqueChars[j]) {
            let createObject = {
              UserId: "",
              label: "",
              type: "bar",
              stack: "",
              backgroundColor: "",
              data: [],
              name: "",
            };
            createObject.data = graphdata[k].data;
            createObject.label = graphdata[k].name;
            let split = graphdata[k].name.split(" ");
            // console.log('split',split)
            createObject.stack = graphdata[k].stack;
            createObject.backgroundColor = graphdata[k].lineColor;
            createObject.UserId = graphdata[k].UserId;
            createObject.name = split[0];
            newArr.push(createObject);
            // console.log("newarr", newArr);
          }
        }

        // if (typeof sevanDaysDate != "string") {
        //   latestBarChart.data.labels = sevanDaysDate;
        // } else {
        //   var a = [];
        //   a.push(sevanDaysDate);
        //   latestBarChart.data.labels = a;
        // }
        // var dateArr = [];
        // latestBarChart.data.labels = sevanDaysDate;
        latestBarChart.data.datasets = newArr;
        latestBarChart._id = uniqueChars[j];

        // console.log("latestBarChart.data.datasets", latestBarChart);
        // for (let m = 0; m < latestBarChart.length; m++) {
        this.graphData.push(latestBarChart);
        //
        // }

        // console.log("latestBarChart.data.datasets", this.graphData);
      }
      this.showvalue = false;
      this.forcRender();
    },

    singleUserGraph(graphdata, sevanDaysDate) {
      this.graphData = [];
      //   console.log("i", graphdata, sevanDaysDate);
      var UserIdsArr = [];
      for (let i = 0; i < graphdata.length; i++) {
        if (graphdata[i].UserId != "") {
          UserIdsArr.push(graphdata[i].UserId);
        }
      }
      let uniqueChars = [...new Set(UserIdsArr)];
      for (let j = 0; j < uniqueChars.length; j++) {
        let latestBarChart = {
          data: {
            labels: [2017, 2018, 2019, 2020, 2021, 2022, 2023],
            datasets: [
              {
                label: "All data add",
                type: "bar",
                stack: "Stack 0",
                backgroundColor: "#eece01",
                data: [30, 31, 32, 33, 34, 35, -36],
              },
            ],
          },

          options: {
            hover: {
              mode: "label",
            },

            hover: {
              mode: "index",
              intersect: false,
            },
            tooltips: {
              mode: "index",
              //enabled: false,
              position: "nearest",
              intersect: false,
              // Updated default tooltip UI
              shadowOffsetX: 1,
              shadowOffsetY: 1,
              shadowBlur: 8,
              shadowColor: "rgba(0, 0, 0, 0.25)",
              backgroundColor: "white",
              titleFontColor: "black",
              bodyFontColor: "black",
            },
            responsive: true,
            legend: {
              display: true,
            },
            interaction: {
              intersect: false,
            },
            scales: {
              xAxes: [
                {
                  stacked: true,
                  labelAngle: -50,
                  ticks: {
                    autoSkip: false,

                    // beginAtZero: true,
                    // maxRotation: 0,
                    // minRotation: 0,
                  },
                },
              ],
              yAxes: [
                {
                  stacked: true,
                },
              ],
            },
          },
        };
        let newArr = [];
        for (let k = 0; k < graphdata.length; k++) {
          //   var createObject = {};
          if (graphdata[k].UserId == uniqueChars[j]) {
            let createObject = {
              UserId: "",
              label: "",
              type: "bar",
              stack: "",
              backgroundColor: "",
              data: [],
              name: "",
            };
            createObject.data = graphdata[k].data;
            createObject.label = graphdata[k].name;
            let split = graphdata[k].name.split(" ");
            // console.log('split',split)
            createObject.stack = graphdata[k].stack;
            createObject.backgroundColor = graphdata[k].lineColor;
            createObject.UserId = graphdata[k].UserId;
            createObject.name = split[0];
            newArr.push(createObject);
            // console.log("newarr", newArr);
          }
        }

        // if (typeof sevanDaysDate != "string") {
        //   latestBarChart.data.labels = sevanDaysDate;
        // } else {
        //   var a = [];
        //   a.push(sevanDaysDate);
        //   latestBarChart.data.labels = a;
        // }
        var dateArr = [];

        if (typeof sevanDaysDate != "string") {
          // console.log("sevanDaysDate", sevanDaysDate);
          if (sevanDaysDate.length == 7) {
            //   console.log("else");

            for (let l = 0; l < sevanDaysDate.length; l++) {
              //   console.log("sevanDaysDate", sevanDaysDate[l]);
              //  const element = array[l];
              // let month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
              var singleDate = sevanDaysDate[l];
              var days = [
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
                "Saturday",
              ];
              var d = new Date(singleDate);
              // console.log(d.getDay());
              var dayName = days[d.getDay()];
              dateArr.push(dayName);
            }
          } else {
            //   console.log("else");
            for (let l = 0; l < sevanDaysDate.length; l++) {
              //   console.log("sevanDaysDateElse", sevanDaysDate[l]);

              //  const element = array[l];
              let month = [
                "January",
                "February",
                "March",
                "April",
                "May",
                "June",
                "July",
                "August",
                "September",
                "October",
                "November",
                "December",
              ];
              var singleDate = sevanDaysDate[l];
              var splitSingleDate = singleDate.split("-");
              // console.log('response.data.date[l]',);
              var dt = new Date(singleDate);
              var finalDate = splitSingleDate[2] + " " + month[dt.getMonth()];
              // console.log("safasfas", typeof(finalDate) );
              dateArr.push(finalDate);
              // console.log('data',dateArr);
            }
          }
          latestBarChart.data.labels = dateArr;
        } else {
          var a = [];
          a.push(sevanDaysDate);
          latestBarChart.data.labels = a;
        }
        // latestBarChart.data.labels = sevanDaysDate;
        latestBarChart._id = uniqueChars[j];
        latestBarChart.data.datasets = newArr;
        // console.log("latestBarChart.data.datasets", latestBarChart);
        this.graphData.push(latestBarChart);
        // console.log("latestBarChart.data.datasets", this.graphData);
      }
      this.showvalue = false;
      this.forcRender();

      // console.log('Uer',uniqueChars);
    },
    firstload() {
      this.showvalue = true;
      axios
        .get(
          process.env.VUE_APP_API_URL + "/lastseven/days/add-edit-delete/all",
          {
            headers: {
              Authorization: "Bearer " + localStorage.getItem("_t"),
            },
          }
        )
        .then((response) => {
          this.optionsData = response.data.remaingUsers;
          this.selectedUsers = response.data.selectedUser;
          this.previousSelectedStructures = this.selectedUsers;
          var dateArr = [];
          if (response.data.date.length == 7) {
            for (let l = 0; l < response.data.date.length; l++) {
              var singleDate = response.data.date[l];
              var days = ["Sun", "Mon", "Tue", "Wed", "Thur", "Fri", "Sat"];
              var d = new Date(singleDate);
              var dayName = days[d.getDay()];
              dateArr.push(dayName);
            }
          }
          this.latestBarChart.data.labels = dateArr;
          var newArr = [];
          for (let index = 0; index < response.data.data.length; index++) {
            let createObject = {
              UserId: "",
              label: "",
              type: "bar",
              stack: "",
              backgroundColor: "",
              data: [],
            };
            createObject.data = response.data.data[index].data;
            createObject.label = response.data.data[index].name;
            createObject.stack = response.data.data[index].stack;
            createObject.backgroundColor = response.data.data[index].lineColor;
            createObject.UserId = response.data.data[index].UserId;
            newArr[index] = createObject;
          }
          this.latestBarChart.data.datasets = newArr;
          this.permantSaveOptionValue = JSON.stringify(
            this.latestBarChart.data.datasets
          );
          this.permantSaveOptionValue = JSON.parse(this.permantSaveOptionValue);
          var a = new Date();
          var b = -6;
          var resulta = a.setDate(a.getDate() + b);
          var someDate = new Date();
          var numberOfDaysToAdd = 0;
          var result = someDate.setDate(someDate.getDate() + numberOfDaysToAdd);
          this.dateRang = {};
          this.dateRange = {
            endDate: new Date(result),
            startDate: new Date(resulta),
          };
          this.forcRender();
          this.showvalue = false;
        });
    },
    forcRender() {
      this.sidebarkey += 1;
    },
    getAddQuestionAccordingUsers(value) {
      if (this.filterIndex == 1) {
        this.showvalue = true;
        var startDate = value.startDate;
        var endDate = value.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        let id = "";
        if (this.selectedUsers) {
          id = this.selectedUsers.value;
        } else {
          id = "";
        }
        var datetime = {
          id: id,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/daily-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);
            var dateArr = [];

            this.latestBarChart.data.labels = response.data.date;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      } else if (this.filterIndex == 2) {
        this.showvalue = true;
        var startDate = value.startDate;
        var endDate = value.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        let id = "";
        if (this.selectedUsers) {
          id = this.selectedUsers.value;
        } else {
          id = "";
        }
        var datetime = {
          id: id,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/weekly-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);

            var dateArr = [];
            // console.log('response.data.date',response.data.date);
            for (let i = 0; i < response.data.date.length; i++) {
              let newDate = response.data.date[i].split("to");
              // console.log(newDate[0]);
              var monthfind1 = new Date(newDate[0]);
              var month1 = monthfind1.toLocaleString("default", {
                month: "short",
              });
              //  console.log(month);
              var day1 = newDate[0].split("-");
              var monthfind2 = new Date(newDate[1]);
              var month2 = monthfind2.toLocaleString("default", {
                month: "short",
              });
              //  console.log(month);
              var day2 = newDate[1].split("-");
              var finaldate =
                day1[2] +
                " " +
                month1 +
                " " +
                "-" +
                " " +
                day2[2] +
                " " +
                month2;
              dateArr.push(finaldate);
            }
            // console.log(dateArr, "fas");

            this.latestBarChart.data.labels = dateArr;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      } else {
        this.showvalue = true;
        var startDate = value.startDate;
        var endDate = value.endDate;
        var month = startDate.getMonth() + 1;
        var day = startDate.getDate();
        var year = startDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var startDate = year + "-" + month + "-" + day;
        var month = endDate.getMonth() + 1;
        var day = endDate.getDate();
        var year = endDate.getFullYear();
        if (day >= 10) {
        } else {
          day = "0" + day;
        }
        if (month >= 10) {
        } else {
          month = "0" + month;
        }
        var lastDate = year + "-" + month + "-" + day;
        let id = "";
        if (this.selectedUsers) {
          id = this.selectedUsers.value;
        } else {
          id = "";
        }
        var datetime = {
          id: id,
          date: {
            startDate: lastDate,
            endDate: startDate,
          },
        };
        //
        axios
          .post(
            process.env.VUE_APP_API_URL + "/singleUser/monthly-report/filter",
            datetime,
            {
              headers: {
                Authorization: "Bearer " + localStorage.getItem("_t"),
              },
            }
          )
          .then((response) => {
            this.optionsData = response.data.remaingUsers;
            this.selectedUsers = response.data.selectedUser;
            this.previousSelectedStructures = this.selectedUsers;
            // let added = this.selectedUsers.filter(
            //   (val) => !this.previousSelectedStructures.includes(val)
            // );
            //   this.latestBarChart.data.labels = response.data.date;
            // this.singleUserGraph(response.data.data, response.data.date);
            var newArr = [];
            for (let index = 0; index < response.data.data.length; index++) {
              let createObject = {
                UserId: "",
                label: "",
                type: "bar",
                stack: "",
                backgroundColor: "",
                data: [],
              };
              createObject.data = response.data.data[index].data;
              createObject.label = response.data.data[index].name;
              createObject.stack = response.data.data[index].stack;
              createObject.backgroundColor =
                response.data.data[index].lineColor;
              createObject.UserId = response.data.data[index].UserId;
              newArr[index] = createObject;
            }
            //   console.log("arr", newArr);
            this.latestBarChart.data.datasets = newArr;
            this.permantSaveOptionValue = this.latestBarChart.data.datasets;
            //   console.log("response.data.date.length", response.data.date.length);
            var dateArr = [];

            this.latestBarChart.data.labels = response.data.date;
            this.spinnerPlayStop = false;
            this.forcRender();
            this.showvalue = false;
          });
      }
      // var startDate = value.startDate;
      // var endDate = value.endDate;
      // var month = startDate.getMonth() + 1;
      // var day = startDate.getDate();
      // var year = startDate.getFullYear();
      // if (day >= 10) {
      // } else {
      //   day = "0" + day;
      // }
      // if (month >= 10) {
      // } else {
      //   month = "0" + month;
      // }
      // var startDate = year + "-" + month + "-" + day;
      // var month = endDate.getMonth() + 1;
      // var day = endDate.getDate();
      // var year = endDate.getFullYear();
      // if (day >= 10) {
      // } else {
      //   day = "0" + day;
      // }
      // if (month >= 10) {
      // } else {
      //   month = "0" + month;
      // }
      // var lastDate = year + "-" + month + "-" + day;
      // var datetime = {
      //   id: this.selectedUsers.value,
      //   date: {
      //     startDate: lastDate,
      //     endDate: startDate,
      //   },
      // };
      // //
      // axios
      //   .post(
      //     process.env.VUE_APP_API_URL +
      //       "/question/daily-report/filter-date/report",
      //     datetime,
      //     {
      //       headers: {
      //         Authorization: "Bearer " + localStorage.getItem("_t"),
      //       },
      //     }
      //   )
      //   .then((response) => {
      //     this.optionsData = response.data.remaingUsers;
      //     this.selectedUsers = response.data.selectedUser;
      //     this.previousSelectedStructures = this.selectedUsers;
      //     // let added = this.selectedUsers.filter(
      //     //   (val) => !this.previousSelectedStructures.includes(val)
      //     // );
      //     //   this.latestBarChart.data.labels = response.data.date;
      //     this.singleUserGraph(response.data.data, response.data.date);
      //     var newArr = [];
      //     for (let index = 0; index < response.data.data.length; index++) {
      //       let createObject = {
      //         UserId: "",
      //         label: "",
      //         type: "bar",
      //         stack: "",
      //         backgroundColor: "",
      //         data: [],
      //       };
      //       createObject.data = response.data.data[index].data;
      //       createObject.label = response.data.data[index].name;
      //       createObject.stack = response.data.data[index].stack;
      //       createObject.backgroundColor = response.data.data[index].lineColor;
      //       createObject.UserId = response.data.data[index].UserId;
      //       newArr[index] = createObject;
      //     }
      //     //   console.log("arr", newArr);
      //     this.latestBarChart.data.datasets = newArr;
      //     this.permantSaveOptionValue = this.latestBarChart.data.datasets;
      //     //   console.log("response.data.date.length", response.data.date.length);
      //     var dateArr = [];

      //     if (typeof response.data.date != "string") {
      //       // console.log("response.data.date.length", response.data.date.length);
      //       if (response.data.date.length == 7) {
      //         //   console.log("else");

      //         for (let l = 0; l < response.data.date.length; l++) {
      //           //  const element = array[l];
      //           // let month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
      //           var singleDate = response.data.date[l];
      //           var days = [
      //             "Sunday",
      //             "Monday",
      //             "Tuesday",
      //             "Wednesday",
      //             "Thursday",
      //             "Friday",
      //             "Saturday",
      //           ];
      //           var d = new Date(singleDate);
      //           // console.log(d.getDay());
      //           var dayName = days[d.getDay()];
      //           dateArr.push(dayName);
      //         }
      //       } else {
      //         //   console.log("else");
      //         for (let l = 0; l < response.data.date.length; l++) {
      //           //  const element = array[l];
      //           let month = [
      //             "January",
      //             "February",
      //             "March",
      //             "April",
      //             "May",
      //             "June",
      //             "July",
      //             "August",
      //             "September",
      //             "October",
      //             "November",
      //             "December",
      //           ];
      //           var singleDate = response.data.date[l];
      //           var splitSingleDate = singleDate.split("-");
      //           // console.log('response.data.date[l]',);
      //           var dt = new Date(singleDate);
      //           var finalDate = splitSingleDate[2] + " " + month[dt.getMonth()];
      //           // console.log("safasfas", typeof(finalDate) );
      //           dateArr.push(finalDate);
      //         }
      //       }
      //       this.latestBarChart.data.labels = dateArr;
      //     } else {
      //       var a = [];
      //       a.push(response.data.date);
      //       this.latestBarChart.data.labels = a;
      //     }
      //     this.spinnerPlayStop = false;
      //     this.forcRender();
      //   });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>
<style >
/* #bar-chart { */
/* width: 550px !important; */
/* height: 550px !important; */
/* } */
</style>
